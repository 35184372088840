.dialog {
    background-color: #fff;
    border-radius: 10px;
    // box-shadow: 3px 5px 15px -5px #000;
    box-shadow: 2px 3px 10px 0px #aaa;
    color: #000;
    left: 50%;
    max-height: calc(100% - 50px);
    max-width: calc(100% - 50px);
    padding: 15px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    .darkMode & {
        // background-color: #484c54;
        // box-shadow: 5px 8px 10px #1a1a1a;
        // color: #ddd;

        background-color: #222;
        box-shadow: 2px 3px 10px 0px #111;
        color: #fff;
    }

    .closeX {
        color: #aaa;
        cursor: pointer;
        font-size: 28px;
        height: 20px;
        line-height: 16px;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 20px;
        z-index: 100;
    }

    .header {
        display: grid;
        font-size: 20px;
        grid-template-columns: auto 1fr;
        height: 24px;
        margin: 3px 8px 20px;

        .tabsContainer {
            display: grid;
            grid-template-columns: auto auto auto auto auto 1fr;
            height: 100%;
            margin-left: 20px;

            .tab {
                border-radius: 8px;
                cursor: pointer;
                display: inline-block;
                font-size: 16px;
                line-height: 16px;
                padding: 4px 10px;
                z-index: 10;

                &:hover, &.selected {
                    background-color: #444;
                }
            }
        }
    }

    .dragHandle {
        cursor: pointer;
        height: 50px;
        left: 0px;
        position: absolute;
        top: 0px;
        width: 100%;
    }

    .dialogContentContainer {
        height: calc(100% - 46px);
    }
}