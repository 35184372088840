.users {

    .usersHeader {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: 10px;

        .lastUpdatedContainer {
            text-align: right;
        }
    }

    .userContainer {
        background-color: #fff;
        border-radius: 16px;
        display: grid;
        font-size: 12px;
        grid-template-columns: 75px 125px 300px;
        height: 16px;
        padding: 8px 10px;
        position: relative;
        margin-bottom: 10px;

        &.userContainerHeader {
            font-weight: bold;
            text-align: center;
        }

        &.smallUserContainer {
            grid-template-columns: 60px 80px 220px;
        }

        .userActionButton {
            cursor: pointer;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}