.bots {

    .botsHeader {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: 10px;

        .lastUpdatedContainer {
            text-align: right;
        }
    }

    .botsSectionHeader {
        font-size: 16px;
        margin: 15px 0px 10px;
    }

    .botsChartContainer {
        background-color: #fff;
        border-radius: 15px;
        padding: 8px 10px;
        margin-bottom: 15px;
    }

    .botContainer {
        background-color: #fff;
        border-radius: 16px;
        display: grid;
        font-size: 12px;
        grid-template-columns: 50px 200px 150px auto 25px;
        height: 16px;
        padding: 8px 10px;
        position: relative;
        margin-bottom: 10px;

        &.smallBotContainer {
            grid-template-columns: 40px 160px 120px 25px;
        }

        &.botContainerHeader {
            font-weight: bold;
            text-align: center;
        }

        .botStrategyInfo {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .botActionButton {
            cursor: pointer;
            position: absolute;
            right: 12px;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}