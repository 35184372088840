.myTable2MasterContainer {
    font-size: 14px;

    .myTable2Container {
        // display: grid;
        // grid-template-rows: 100%;
        // max-height: 100%;
        overflow: auto;
        // padding-bottom: 1px;

        &::-webkit-scrollbar {
            display: none; /* Hide scrollbar for Chrome, Safari and Opera */
        }
        -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
        scrollbar-width: none;  /* Hide scrollbar for Firefox */

        .myTable2 {
            display: grid;
            // grid-template-rows: 29px calc(100% - 29px); // doesnt seem to work in firefox
            grid-template-rows: 29px 1fr;

            &.hasHeaderGroups {
                // grid-template-rows: 29px 29px calc(100% - 58px); // doesnt seem to work in firefox
                grid-template-rows: 29px 29px 1fr;
            }

            .headerRow {
                display: grid;
                // margin-bottom: -1px;

                .headerCell {
                    border: 1px solid #ddd;
                    cursor: pointer;
                    font-size: 14px;
                    margin-right: -1px;
                    overflow-x: auto;
                    overflow-y: hidden;
                    padding: 6px 3px;
                    text-align: center;
                    white-space: nowrap;

                    &::-webkit-scrollbar {
                        display: none; /* Hide scrollbar for Chrome, Safari and Opera */
                    }
                    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
                    scrollbar-width: none;  /* Hide scrollbar for Firefox */

                    .headerCellCheckbox {
                        margin: 0px 4px 0px;
                        transform: translateY(1px);
                    }
                }

                &:nth-child(2) {
                    .headerCell {
                        border-top: none;
                    }
                }
            }

            .rowsContainer {
                overflow-y: auto;

                &::-webkit-scrollbar {
                    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
                }
                -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
                scrollbar-width: none;  /* Hide scrollbar for Firefox */

                .rowsGridContainer {
                    display: grid;

                    .tableRow {
                        display: contents;

                        .tableCell {
                            border: 1px solid #ddd;
                            font-size: 13px;
                            margin: 0px -1px -1px 0px;
                            overflow: auto;
                            padding: 5px 4px;
                            white-space: nowrap;

                            &::-webkit-scrollbar {
                                display: none; /* Hide scrollbar for Chrome, Safari and Opera */
                            }
                            -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
                            scrollbar-width: none;  /* Hide scrollbar for Firefox */

                            &.centered {
                                text-align: center;
                            }

                            &.right {
                                text-align: right;
                            }

                            &.moneyCell {
                                display: grid;
                                grid-template-columns: 10px calc(100% - 10px);

                                padding-right: 6px;
                                // position: relative;
                                text-align: right;

                                &::before {
                                    content: '$';
                                    left: 6px;
                                    // position: absolute;
                                }
                            }

                            &.tableCellClickMenu {
                                cursor: pointer;
                            }

                            &.tableCellCheckboxContainer {
                                cursor: pointer;

                                .tableCellCheckbox {
                                    margin: 0px 4px 0px;
                                    transform: translateY(1px);
                                }
                            }

                            .switchLabel {
                                height: 15px;
                                padding: 0px 12px;
                            }
                        }

                        &:first-child {
                            .tableCell {
                                border-top: none;
                            }
                        }

                        &:last-child .tableCell {
                            margin-bottom: 0px;
                        }
                    }
                }
            }

            &.defaultColors {
                .headerRow {
                    .headerCell {
                        background-color: #ffff81;
                    }
                }

                .tableRow {
                    .tableCell {
                        background-color: #fff;
                    }

                    &.darkRow .tableCell {
                        background-color: #f3f3f3;
                    }

                    &.highlighted .tableCell {
                        background-color: #96ceff;
                        /* border-color: #676767 !important; */
                    }
                }
            }

            &.compressed {
                // grid-template-rows: 24px calc(100% - 24px); // doesnt seem to work in firefox
                grid-template-rows: 24px 1fr;

                .headerRow {
                    .headerCell {
                        padding: 3px 3px;
                    }
                }

                .rowsContainer {
                    .rowsGridContainer {
                        .tableRow {
                            .tableCell {
                                padding: 3px 4px;

                                &.moneyCell {
                                    padding-right: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .tableExtras {
        margin-top: 5px;

        .buttonContainer {
            width: auto;
            display: inline-block;
            height: auto;
            margin-bottom: 0px;
            margin-left: 10px;

            .button {
                padding: 2px 5px;
            }
        }
    }
}
